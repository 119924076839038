<template>
  <div class="container-fluid bg-light py-3">
    <main class="container-xl">
      <template v-if="esDescarga">
        <h1>Gracias por su descarga</h1>
        <p class="fs-3" >Se ha enviado un correo a {{ correo }} para comenzar la instalación.</p>
        <p class="fs-3" >Estamos a su disposición para proporcionarle mayor información sobre el sistema {{ producto }}.</p>
      </template>
      <template v-else>
        <h1>Agradecemos su interes en {{ producto }}</h1>
        <p class="fs-3" >Se ha enviado un correo a {{ correo }}.</p>
        <p class="fs-3">Favor de confirmar su cita, ya sea por teléfono o correo electrónico.</p>
      </template>
    </main>
  </div>
</template>
<script>

export default {
  name: 'DescargaProducto',
  props: {
    producto: String,
    correo: String,
  },
  computed:{
    esDescarga(){
      return this.producto.includes('ERP') || this.producto.includes('Bás') || this.producto.includes('Nóm');
    }
  }
}
</script>